(function() {
  $(document).on('click', '.dropdown_menu_sub_trigger a', function(e) {
    $(e.currentTarget).parent().toggleClass('is_open');
    return false;
  });

  $(document).on('show.bs.dropdown', function(e) {
    // Find active sub items and open their parent dropdown
    return $(e.target).find('.dropdown_menu_sub li.active').parent().closest('li').prev('.dropdown_menu_sub_trigger').addClass('is_open');
  });

}).call(this);
