(function() {
  (function($, window) {
    var Toggler;
    Toggler = (function() {
      // Define the plugin class
      class Toggler {
        constructor($el, options) {
          this.options = $.extend({}, this.defaults, options);
          this.$el = $el;
          this.$input = this.$el.find("input");
          if (this.$input.data('on-text')) {
            this.options.on_text = this.$input.data('on-text');
          }
          if (this.$input.data('off-text')) {
            this.options.off_text = this.$input.data('off-text');
          }
          this.$togglerInner = $("<div class='toggler-inner' />").prependTo(this.$el);
          if (this.options.reverse) {
            this.$togglerInner.addClass('toggler-reverse');
          }
          this.$textSpan = $(`<span>${this.options.off_text}</span>`).prependTo(this.$togglerInner);
          this.$textSpan.css('min-width', this.$textSpan.width());
          this.value = this.$input.val() || this.$el.data('value') || this.options.default_value;
          if (this.$input.length === 0) {
            this.$input = void 0;
          }
          if (this.isOn()) {
            this.toggleClassAndText();
          }
          if (this.$input && (this.$input.attr('disabled') != null)) {
            return this.$togglerInner.addClass('disabled');
          }
          setTimeout(() => {
            this.$togglerInner.addClass('loaded');
            this.$togglerInner.on('click.toggler', () => {
              this.toggleClassAndText();
              return this.toggleValue();
            });
            return this.$el.on('toggler:change', this.options.change);
          }, 1);
        }

        toggleClassAndText() {
          this.$togglerInner.toggleClass('on');
          return this.$textSpan.text(this.$togglerInner.hasClass('on') ? this.options.on_text : this.options.off_text);
        }

        toggleValue() {
          this.value = ((this.value === 'f' || this.value === 'false') ? 't' : 'f');
          if (this.$input) {
            this.$input.val(this.value);
          }
          return this.$el.trigger('toggler:change', this.value);
        }

        isOn() {
          return this.value === 't' || this.value === 'true';
        }

      };

      Toggler.prototype.defaults = {
        on_text: "On",
        off_text: "Off",
        default_value: 'f',
        reverse: false,
        change: function(e, value) {}
      };

      return Toggler;

    }).call(this);
    // Define the plugin
    return $.fn.extend({
      toggler: function(option, ...args) {
        return this.each(function() {
          var data;
          data = $(this).data('toggler');
          if (!data) {
            $(this).data('toggler', (data = new Toggler($(this), option)));
          }
          if (typeof option === 'string') {
            return data[option].apply(data, args);
          }
        });
      }
    });
  })(window.jQuery, window);

  $(document).on('turbolinks:load', function() {
    return $(".js-toggler").toggler();
  });

}).call(this);
