(function() {
  $(document).on('click', '.js_invoice_history', function(e) {
    e.preventDefault();
    return $.ajax({
      url: $(this).attr('href'),
      dataType: 'html',
      success: (html) => {
        return $(this).replaceWith(html);
      }
    });
  });

}).call(this);
