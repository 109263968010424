(function() {
  var indexOf = [].indexOf;

  $(document).on('turbolinks:load', function() {
    return $('[data-show-if-radio-selected]').each(function() {
      var $el, $inputs, name, showHide, vals;
      $el = $(this);
      [name, vals] = $el.data('show-if-radio-selected').split('|');
      vals = vals.split(',');
      $inputs = $(`[name=\"${name}\"]`);
      showHide = function() {
        var val;
        val = $inputs.filter(':checked').first().val();
        if (indexOf.call(vals, val) >= 0) {
          return $el.show();
        } else {
          return $el.hide();
        }
      };
      $inputs.on('click', showHide);
      return showHide();
    });
  });

}).call(this);
