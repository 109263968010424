(function() {
  $(document).on('turbolinks:load', function() {
    $('input, textarea').placeholder();
    $('[data-toggle=tooltip]').tooltip();
    $('.dropdown_navbar > a').tooltip({
      placement: 'bottom'
    });
    $('body').styledControls();
    return $('body').styledSelect();
  });

}).call(this);
