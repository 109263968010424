import './plugins/modernizr_custom'
import './core/hacks.coffee'
import './core/transition'
import './core/dropdown_menu_sub.coffee'
import './core/dropdowns'
import './core/tooltips'
import './core/modals'
import './core/buttons'
import './core/styled_select.coffee'
import './core/styled_controls.coffee'

window.Dvl = {};
