// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.

import './src/jquery'
import 'jquery-ujs';
import 'jquery.placeholder';
import 'vendor/dvl/core'
import 'vendor/dvl/components/flashes.coffee'
import 'vendor/dvl/components/navbar.coffee'
import 'turbolinks';
import './shared/airbrake';
import './shared/show_if_radio_selected.js.coffee';
import './shared/toggler.js.coffee';
import './frontend/app_form.coffee';
import './frontend/credit_card_form.coffee';
import './frontend/invoice_history.coffee';
import './frontend/main.coffee';
import './frontend/pretty_file_input.coffee';
import './frontend/toggle_class.coffee';

var Turbolinks = require("turbolinks")
Turbolinks.start()
