(function() {
  (function($, window) {
    var PrettyFileInput;
    PrettyFileInput = (function() {
      class PrettyFileInput {
        constructor($el, options) {
          var base, base1, i, removeKey;
          this.$el = $el;
          this.options = $.extend({}, this.defaults, options, this.$el.data('pfi'));
          this.$form = this.$el.closest('form');
          this.$input = this.$el.find('input');
          this.$filename = this.$el.find('.pfi_existing_filename');
          this.$status = this.$el.find('.pfi_status');
          if (!this.options.persisted) {
            this.$input.attr('name', this.options.name);
          }
          removeKey = this.options.name.match(/\[/) ? (i = this.options.name.lastIndexOf('['), `${this.options.name.substring(0, i)}[remove_${this.options.name.substring(i + 1, this.options.name.length)}`) : `remove_${this.options.name}`;
          this.removeParams = {};
          this.removeParams[removeKey] = true;
          (base = this.options).action || (base.action = this.$form.attr('action'));
          (base1 = this.options).method || (base1.method = this.$form.find('[name=_method]').val() || this.$form.attr('method'));
          this._bindEvents();
        }

        remove() {
          if (this.options.persisted) {
            this._ajaxRemove();
          } else {
            this.$input.val('');
          }
          return this.$el.removeClass('is_uploaded').trigger('pfi:removed');
        }

        _baseParams() {
          return $.extend({
            pretty_file_input: true
          }, this.options.additionalParams);
        }

        _ajaxRemove() {
          return $.ajax({
            url: this.options.action,
            type: this.options.method,
            dataType: 'json',
            data: $.extend(this._baseParams(), this.removeParams)
          });
        }

        _ajaxUpload() {
          var $tmpForm, formData;
          $tmpForm = this._createTemporaryForm();
          formData = new FormData($tmpForm[0]);
          return $.ajax({
            url: this.options.action,
            type: 'POST',
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            xhr: () => {
              var xhr;
              xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener('progress', (e) => {
                var percentComplete;
                if (e.lengthComputable) {
                  percentComplete = (e.loaded / e.total) * 100;
                  return this.$status.text(percentComplete === 100 ? 'Finishing' : `Uploading (${percentComplete}%)`);
                }
              });
              return xhr;
            }
          }).done((data) => {
            return this._uploadSuccess(data);
          }).fail((error) => {
            return this._uploadError(error);
          }).always(() => {
            this.$input.show();
            return $tmpForm.remove();
          });
        }

        _createTemporaryForm() {
          var $oldInput, form;
          form = $(`<form action='${this.options.action}' method='post' style='display: inline;'>
  <input type='hidden' name='_method' value='${this.options.method}' />
</form>`);
          $oldInput = this.$input;
          this.$input = $oldInput.clone().hide().val('').insertBefore($oldInput);
          this._bindInputChange();
          $oldInput.appendTo(form);
          // We only add the name immediately before uploading because we
          // don't want to send the input value during submission of an
          // outer form.
          $oldInput.attr('name', this.options.name);
          form.insertBefore(this.$input);
          return form;
        }

        _uploadSuccess(data) {
          if ((data != null ? data.additionalParams : void 0) != null) {
            this.options.additionalParams = data.additionalParams;
          }
          this.$status.text('');
          return this.$el.addClass('is_uploaded').trigger('pfi:uploaded');
        }

        _uploadError() {
          this.$status.text('Error');
          return setTimeout((() => {
            return this.$status.text('');
          }), 2000);
        }

        _eventToFilename(e) {
          if (e.target.files != null) {
            return e.target.files[0].name;
          } else if (e.target.value) {
            return e.target.value.replace(/^.+\\/, '');
          }
        }

        _onChange(e) {
          this.$filename.text(this._eventToFilename(e));
          if (this.options.persisted) {
            this.$status.text('Uploading...');
            return this._ajaxUpload();
          } else {
            return this._uploadSuccess();
          }
        }

        _bindEvents() {
          this.$el.on('click', '[data-pfi-remove]', $.proxy(this.remove, this));
          return this._bindInputChange();
        }

        // FF6 doesn't bubble the 'change' event, so we need to bind
        // directly to the @$input. Since we need to re-bind later,
        // we break this out into a separate method.
        _bindInputChange() {
          return this.$input.on('change', $.proxy(this._onChange, this));
        }

      };

      PrettyFileInput.prototype.defaults = {
        additionalParams: {},
        name: void 0,
        persisted: void 0,
        action: void 0,
        method: void 0
      };

      return PrettyFileInput;

    }).call(this);
    return $.fn.extend({
      prettyFileInput: function(option, ...args) {
        return this.each(function() {
          var data;
          data = $(this).data('pretty-file-input');
          if (!data) {
            $(this).data('pretty-file-input', (data = new PrettyFileInput($(this), option)));
          }
          if (typeof option === 'string') {
            return data[option].apply(data, args);
          }
        });
      }
    });
  })(window.jQuery, window);

  $(document).on('turbolinks:load', function() {
    return $('[data-pfi]').prettyFileInput();
  });

}).call(this);
