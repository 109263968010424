(function() {
  var StyledSelect;

  StyledSelect = (function() {
    class StyledSelect {
      constructor($el, options) {
        var i, j, len, ref;
        this.$el = $el;
        this.options = $.extend({}, this.defaults, options);
        ref = ['width'];
        for (j = 0, len = ref.length; j < len; j++) {
          i = ref[j];
          if (this.$el.data(i) != null) {
            this.options[i] = this.$el.data(i);
          }
        }
        if (this.$el.data('no-blank-class') != null) {
          this.options.noBlankClass = true;
        }
        this.initWrapper();
        this.$el.on('change', $.proxy(this._change, this));
        this.$el.on('styled_select:refresh', $.proxy(this._change, this));
        this._change();
      }

      initWrapper() {
        var i, j, len, ref;
        this.$wrapper = $('<div class="styled_select_wrapper" />');
        this.$span = $('<div class="styled_select" />');
        this.setWidth();
        ref = ['small', 'large'];
        for (j = 0, len = ref.length; j < len; j++) {
          i = ref[j];
          if (this.$el.hasClass(i)) {
            this.$wrapper.addClass(i);
          }
        }
        this.$el.wrap(this.$wrapper);
        return this.$span.appendTo(this.$el.parent());
      }

      setWidth() {
        if (this.options.width === 'full') {
          return this.$wrapper.addClass('full');
        } else if (this.options.width === 'auto') {
          return this.$wrapper.addClass('auto');
        } else if (this.options.width) {
          return this.$wrapper.width(this.options.width);
        }
      }

      _change() {
        var $selected;
        $selected = this.$el.find('option:selected');
        if ($selected.val()) {
          this.$span.text($selected.text());
          if (!this.options.noBlankClass) {
            return this.$span.removeClass('is_blank');
          }
        } else {
          this.$span.text($selected.text());
          if (!this.options.noBlankClass) {
            return this.$span.addClass('is_blank');
          }
        }
      }

    };

    StyledSelect.prototype.defaults = {
      width: void 0 // options are numeric (pixel units), 'full', or 'auto'
    };

    return StyledSelect;

  }).call(this);

  window.StyledSelect = StyledSelect;

  $.fn.extend({
    styledSelect: function(opts) {
      return $(this).find('select:not([data-no-styled-select])').each(function() {
        if (!this.styledSelect) {
          this.styledSelect = true;
          return new StyledSelect($(this), opts);
        }
      });
    }
  });

}).call(this);
