(function() {
  var FLASH_ALERT_LENGTH, TRANSITION_LENGTH;

  FLASH_ALERT_LENGTH = 3500;

  TRANSITION_LENGTH = 300;

  Dvl.FlashHide = function($flash) {
    $flash.addClass('is_hiding');
    if ($.support.transition) {
      return $flash.on('bsTransitionEnd', function() {
        return $flash.remove();
      }).emulateTransitionEnd(TRANSITION_LENGTH);
    } else {
      return $flash.remove();
    }
  };

  Dvl.Flash = function(flashType, message, linksHTML) {
    var $flash;
    // Remove existing flashes
    $('.flash').remove();
    $flash = $(`<div class="flash flash_${flashType}">
  <span>${message}</span>
  <a class='flash_close' href='#'>&times;</a>
</div>`);
    $flash.on('click', '.flash_close', function() {
      return Dvl.FlashHide($flash);
    });
    if (linksHTML) {
      $flash.append(`<div class='flash_links'>${linksHTML}</span>`);
    }
    $flash.appendTo('body');
    // Push this to the next frame so that the transition works :/
    setTimeout(function() {
      return $flash.addClass('is_visible');
    }, 0);
    // Hide flashes automatically unless they have links
    if (!linksHTML) {
      return setTimeout(function() {
        return Dvl.FlashHide($flash);
      }, FLASH_ALERT_LENGTH);
    }
  };

  $(document).on('page:before-unload', function() {
    return $('.flash').remove();
  });

}).call(this);
