(function() {
  $(document).on('turbolinks:load', function() {
    var $stripe_trigger, handler, loadStripe;
    if (!($stripe_trigger = $('#stripeCheckoutTrigger'))[0]) {
      return;
    }
    handler = void 0;
    loadStripe = function(cb) {
      return $.getScript('https://checkout.stripe.com/checkout.js', function() {
        handler = StripeCheckout.configure({
          key: $stripe_trigger.data('stripe-pk'),
          image: $stripe_trigger.data('stripe-logo'),
          name: "DOBT",
          description: "Update your billing information",
          panelLabel: "Save",
          allowRememberMe: false,
          email: $stripe_trigger.data('stripe-email'),
          token: function(token, args) {
            $('#billing_stripe_token').val(token.id);
            $('#billing_last_four').val(token.card.last4);
            return $stripe_trigger.closest('form').submit();
          }
        });
        return typeof cb === "function" ? cb() : void 0;
      });
    };
    $stripe_trigger.hover(loadStripe);
    return $stripe_trigger.click(function(e) {
      e.preventDefault();
      if (handler) {
        return handler.open();
      } else {
        return loadStripe(function() {
          return handler.open();
        });
      }
    });
  });

}).call(this);
