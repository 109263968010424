(function() {
  $(document).on('turbolinks:load', function() {
    var $appForm, handler;
    if (!($appForm = $('form.edit_organization_app'))[0]) {
      return;
    }
    if (!$appForm.data('needs-cc')) {
      return;
    }
    handler = void 0;
    $.getScript('https://checkout.stripe.com/checkout.js', function() {
      return handler = StripeCheckout.configure($.extend($appForm.data('stripe'), {
        name: 'DOBT',
        description: 'Update your billing information',
        panelLabel: 'Save',
        allowRememberMe: false,
        token: function(token, args) {
          $('#organization_app_billing_stripe_token').val(token.id);
          $('#organization_app_billing_last_four').val(token.card.last4);
          return $appForm.submit();
        }
      }));
    });
    $('#organization_app_subscription_app_plan_id').on('change', function() {
      return $appForm.find('.button').text('Enter payment information');
    });
    return $appForm.one('submit', function(e) {
      if ($('#organization_app_subscription_app_plan_id').val()) {
        e.preventDefault();
        return handler.open();
      }
    });
  });

}).call(this);
