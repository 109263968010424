import { Notifier } from '@airbrake/browser';

let airbrake = null
if (process.env.AIRBRAKE_PROJECT_ID && process.env.AIRBRAKE_PROJECT_API_KEY) {
// The env variable is required at config time. Since we always build for
// production we cannot dynamically infer the environment. This hack ensures
// that we report the environment correctly.
  const env = () => {
    const origin = window.location.origin;
    if (origin.indexOf('staging') !== -1) {
      return 'staging'
    } else if (origin.indexOf('dashboard.dobt.co') !== -1) {
      return 'production'
    } else {
      return 'development'
    }
    ;
  };

  airbrake = new Notifier({
    projectId: process.env.AIRBRAKE_PROJECT_ID,
    projectKey: process.env.AIRBRAKE_PROJECT_API_KEY,
    keysBlocklist: ["password", "api_key", "(?-mix:^((?-mix:client_secret|code|authentication_token|access_token|refresh_token))$)"]
  });

  airbrake.addFilter((notice) => {
    notice.context.environment = env;
    return notice;
  });
}

export default airbrake;
